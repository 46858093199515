import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { deleteTreeCondition } from "../../features/TreeConditions/treeConditionsActions";
import {
  updateAddNoteCustomer,
  updateAddNoteOperation,
  updateAddNoteOrder,
} from "../../features/operation/operationActions";

import { getFormErrorMessage } from "../../utils/getFormErrorMessage";
import { formatCurrency } from "./../../utils/formatCurrency";
import { apiUrl } from "../../utils/apiURL";

import ShowTreeDetails from "./showTreeDetails";
import EditTree from "./EditTree";
import AddTree from "./AddTree";
import VideoPlayerDialog from "./VideoPlayerDialog";
import AddOrderLineOperation from "../OrderLines/AddOrderLineOperation";

const FormField = ({
  name,
  label,
  control,
  errors,
  disabled = false,
  children,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <div className="flex flex-col">
        <label
          htmlFor={field.name}
          className={classNames({ "p-error": errors.value })}
        >
          {label}
        </label>
        <span>{children(field, fieldState, disabled)}</span>
        {getFormErrorMessage(field.name, errors)}
      </div>
    )}
  />
);

const EditNoteDialog = ({
  refetch,
  operationData,
  setPage,
  toast,
  operationsRefetch,
  isLoading,
  operationTableRefetch,
}) => {
  const [showOrderLine, setShowOrderLine] = useState(false);

  const [showTreeDetails, setShowTreeDetails] = useState(false);
  const [showTreeEdit, setShowTreeEdit] = useState(false);
  const [showAddTree, setShowAddTree] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [operationId, setOperationId] = useState(null);
  const [treeId, setTreeId] = useState(null);
  const [kitLocationImages, setKitLocationImages] = useState([]);
  const [poleLocationImages, setPoleLocationImages] = useState([]);
  const [treesMapImages, setTreesMapImages] = useState([]);
  const [videoVisible, setVideoVisible] = useState(false);
  const [file, setFile] = useState("");

  const isUnWrapping =
    operationData?.treatment?.title?.split(" - ")[0] === "Unwrapping";

  useEffect(() => {
    if (operationData) {
      setKitLocationImages(operationData?.customer?.kit_location_imgs || []);
      setPoleLocationImages(operationData?.customer?.pole_location_imgs || []);
      setTreesMapImages(operationData?.customer?.trees_map || []);
    }
  }, [operationData, isUnWrapping]);

  const dispatch = useDispatch();
  const { updateCustomerLoading, updateOperationLoading } = useSelector(
    (state) => state.operation
  );

  const statusOPtions = [
    { name: "Tentative", code: "Tentative" },
    { name: "Set", code: "Set" },
    { name: "Done", code: "Done" },
  ];

  const modeOfPaymentOptions = [
    { name: "Cash", code: "Cash" },
    { name: "Credit Card", code: "Credit Card" },
    { name: "Check/Zelle", code: "Check" },
  ];

  const onDeleteTree = (id) => {
    dispatch(deleteTreeCondition({ tree_id: id })).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const confirm2 = (id) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => onDeleteTree(id),
      reject,
    });
  };

  const onUpload = (e) => {
    console.log("Kit location upload response:", e.xhr.response);
    console.log("Using API URL:", apiUrl);

    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });

    const response = JSON.parse(e.xhr.response);
    const imageUrl = response.data;

    setKitLocationImages((prevImages) => {
      if (!Array.isArray(prevImages)) {
        console.error("prevImages is not an array:", prevImages);
        return [imageUrl]; // Return a new array with the image
      }
      return [...prevImages, imageUrl];
    });
  };

  const onUploadPoleLocation = (e) => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });

    const response = JSON.parse(e.xhr.response);
    const imageUrl = response.data;

    setPoleLocationImages((prevImages) => {
      if (!Array.isArray(prevImages)) {
        console.error("prevImages is not an array:", prevImages);
        return [imageUrl]; // Return a new array with the image
      }
      return [...prevImages, imageUrl];
    });
  };

  const onUploadTreesMap = (e) => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });

    const response = JSON.parse(e.xhr.response);
    const imageUrl = response.data;

    setTreesMapImages((prevImages) => {
      if (!Array.isArray(prevImages)) {
        console.error("prevImages is not an array:", prevImages);
        return [imageUrl]; // Return a new array with the image
      }
      return [...prevImages, imageUrl];
    });
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (operationData) {
      reset({
        date: operationData?.date_of_tech_visit
          ? new Date(operationData?.date_of_tech_visit)
          : "",
        comments: operationData?.notes_comments || "",
        tallestFrame: operationData?.customer?.tallest_frame || "",
        howPowered: operationData?.customer?.how_powered || "",
        brokenFrames: operationData?.customer?.broken_frames || "",
        kitLocationText: operationData?.customer?.kit_location || "",
        poleLocationText: operationData?.customer?.pole_location || "",
        to_do_notes: operationData?.customer?.to_do_notes || "",
        commentsTech: operationData?.internal_notes || "",
        payment_status: operationData?.order?.payment_status || "",
        modeOfPayment:
          {
            name:
              operationData?.order?.mode_of_payment === "Check"
                ? "Check/Zelle"
                : operationData?.order?.mode_of_payment,
            code: operationData?.order?.mode_of_payment,
          } || "",
        clientBillingNotes: operationData?.client_billing_notes || "",
        status: {
          name: operationData?.status,
          code: operationData?.status,
        },
        total_number_of_trees: operationData?.total_number_of_trees || 0,
        orderUnpaidAmount: operationData?.order?.order_unpaid_amount,
        road_sequence: operationData?.road_sequence,
        want_treat_response: operationData?.want_treat_response,
        // Add unwrapping-specific fields
        extensions_needed: operationData?.extensions_needed || "",
        pole_replacement_needed: operationData?.pole_replacement_needed || "",
        replacement_cover_needed: operationData?.replacement_cover_needed || "",
        water_location: operationData?.water_location || "",
      });
    }
  }, [reset, operationData]);

  const onSubmit = (data) => {
    function formatDate(dateString) {
      if (!dateString) return null;

      const date = new Date(dateString);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }

    const formattedDate = formatDate(data?.date);

    const operationObj = {
      id: operationData?.id,
      date_of_tech_visit: formattedDate,
      notes_comments: data?.comments,
      number_of_old_trees_to_wrap: data?.oldTreesToWrap,
      extension_type: data?.extensionType,
      extension_note: data?.extensionNotes,
      number_of_extension_pole: data?.extensionPoleNumber,
      number_of_extra_lights: data?.extraLightNumber,
      internal_notes: data?.commentsTech,
      status: data?.status?.name,
      travel_surcharge: data?.travel_surcharge,
      total_number_of_trees: data?.total_number_of_trees,
      client_billing_notes: data?.clientBillingNotes,
      want_treat_response: data?.want_treat_response,
      road_sequence: data?.road_sequence,

      // Add unwrapping fields if this is an unwrapping operation
      ...(isUnWrapping && {
        extensions_needed: data?.extensions_needed,
        pole_replacement_needed: data?.pole_replacement_needed,
        replacement_cover_needed: data?.replacement_cover_needed,
        water_location: data?.water_location,
      }),
    };

    const customerObj = {
      tallest_frame: data?.tallestFrame,
      how_powered: data?.howPowered,
      broken_frames: data?.brokenFrames,
      id: operationData?.customer?.id,
      kit_location: data?.kitLocationText,
      pole_location: data?.poleLocationText,
      kit_location_imgs: kitLocationImages,
      pole_location_imgs: poleLocationImages,
      trees_map: treesMapImages,
      to_do_notes: data?.to_do_notes,
    };

    const orderObj = {
      id: operationData?.order?.id,
      mode_of_payment: data?.modeOfPayment?.code,
      ...(isUnWrapping && { payment_status: data?.payment_status }),
    };

    if (
      operationData?.order_id &&
      operationData?.order?.payment_status !== "Paid in Full"
    ) {
      // Update order, then operation, then customer
      dispatch(updateAddNoteOrder(orderObj)).then((res) => {
        if (res?.payload?.data?.status === "Success") {
          dispatch(updateAddNoteOperation(operationObj)).then((res) => {
            if (res?.payload?.data?.status === "Success") {
              dispatch(updateAddNoteCustomer(customerObj)).then((res) => {
                if (res.payload.status === 200) {
                  setOperationId("");
                  operationTableRefetch();
                  setPage("operationDetails");
                  operationsRefetch();
                  operationTableRefetch();
                } else {
                  toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: res.payload,
                    life: 3000,
                  });
                }
              });
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: res.payload,
                life: 3000,
              });
            }
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.payload,
            life: 3000,
          });
        }
      });
    } else {
      // Only update operation and customer
      dispatch(updateAddNoteOperation(operationObj)).then((res) => {
        if (res?.payload?.data?.status === "Success") {
          dispatch(updateAddNoteCustomer(customerObj)).then((res) => {
            if (res.payload.status === 200) {
              setOperationId("");
              operationTableRefetch();
              setPage("operationDetails");
              operationsRefetch();
              operationTableRefetch();
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: res.payload,
                life: 3000,
              });
            }
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.payload,
            life: 3000,
          });
        }
      });
    }
  };

  const onRemoveImage = (e, fileToRemove) => {
    e.preventDefault();
    const filteredImages = kitLocationImages.filter(
      (file) => file !== fileToRemove
    );
    setKitLocationImages(filteredImages);
  };

  const onRemovePoleLocationImages = (e, fileToRemove) => {
    e.preventDefault();
    const filteredImages = poleLocationImages.filter(
      (file) => file !== fileToRemove
    );
    setPoleLocationImages(filteredImages);
  };

  const onRemoveTreesMapImages = (e, fileToRemove) => {
    e.preventDefault();
    const filteredImages = treesMapImages.filter(
      (file) => file !== fileToRemove
    );
    setTreesMapImages(filteredImages);
  };

  // Media image gallery component to reuse for different image types
  const MediaGallery = ({ images, onRemove }) => (
    <div className="flex flex-wrap gap-4 my-4">
      {images?.map((file) => {
        const extension = file.split(".").pop().toLowerCase();
        if (
          extension === "png" ||
          extension === "jpg" ||
          extension === "jpeg" ||
          extension === "gif"
        ) {
          return (
            <div className="relative" key={file}>
              <Button
                icon="pi pi-times"
                rounded
                text
                aria-label="Cancel"
                onClick={(e) => onRemove(e, file)}
                className="absolute z-[99999999999999999999999999999] -top-[25px] -right-[25px] m-0 p-0 text-red-500"
              />
              <Image
                key={file}
                src={file}
                zoomSrc={file}
                alt="Image"
                width="80"
                height="60"
                preview
                imageClassName="w-20 h-20"
              />
            </div>
          );
        } else if (extension === "mp4" || extension === "mov") {
          return (
            <div className="relative" key={file}>
              <Button
                icon="pi pi-times"
                rounded
                text
                aria-label="Cancel"
                onClick={(e) => onRemove(e, file)}
                className="absolute z-[99999999999999999999999999999] -top-[25px] -right-[25px] m-0 p-0 text-red-500"
              />
              <Button
                icon="pi pi-play"
                className="w-20 h-20"
                onClick={(e) => {
                  e.preventDefault();
                  setVideoVisible(true);
                  setFile(file);
                }}
              />
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );

  // Tree condition section component
  const TreeConditionsSection = () => (
    <div className="my-4 p-field">
      <p>Trees Conditions</p>
      {operationData?.customer?.technician_reports.map((treeCondition) => {
        const { id, tree_number } = treeCondition;
        return (
          <div
            key={id}
            className="flex items-center justify-between px-2 my-4 bg-white rounded-xl hover:cursor-pointer"
            onClick={() => {
              setShowTreeDetails(true);
              setTreeId(id);
            }}
          >
            <p>{tree_number ? tree_number : "Not exists"}</p>
            <div>
              <Button
                icon="pi pi-file-edit"
                text
                severity="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowTreeEdit(true);
                  setTreeId(id);
                }}
              />
              <Button
                icon="pi pi-trash"
                text
                severity="danger"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  confirm2(id);
                }}
              />
            </div>
          </div>
        );
      })}
      <Button
        icon="pi pi-plus"
        severity="secondary"
        className="px-12 my-4"
        onClick={(e) => {
          e.preventDefault();
          setShowAddTree(true);
          setCustomerId(operationData?.customer?.id);
          setOperationId(operationData?.id);
        }}
      />
    </div>
  );
  const ProductsOrderedSection = () =>
    operationData?.order ? (
      <div className="mb-4">
        <h3 className="text-xl font-bold">Products Ordered</h3>
        <div className="flex flex-col gap-2">
          {operationData?.order?.line_items?.map((item) => (
            <p
              className={`w-full py-3 pl-2 font-bold text-black rounded-lg shadow-lg bg-slate-200`}
              key={item?.id}
            >
              {item?.line_item_quantity} X {item?.item_type} -{" "}
              {item?.item_type === "service"
                ? formatCurrency(item?.unit_price)
                : formatCurrency(item?.unit_price_override)}{" "}
            </p>
          ))}
        </div>
        {isUnWrapping && operationData?.order && (
          <div>
            <Button
              label="Add OrderLine"
              onClick={() => {
                setShowOrderLine(true);
              }}
              className="w-full mt-4"
              disabled={operationData?.order?.payment_status === "Paid in Full"}
            />
          </div>
        )}
      </div>
    ) : (
      <div className="flex items-center justify-center mx-auto my-4 rounded-md shadow-lg md:w-1/3 bg-slate-200">
        <p className="p-2 font-bold md:text-xl">No Order in this Operation</p>
      </div>
    );

  const FileUploadSection = ({
    id,
    label,
    onUploadHandler,
    images,
    onRemoveHandler,
  }) => {
    const handleUpload = (e) => {
      try {
        onUploadHandler(e);
      } catch (error) {
        console.error(`Error in upload handler for ${id}:`, error);
        toast.current.show({
          severity: "error",
          summary: "Upload Failed",
          detail: `Error in upload handler for ${id}`,
          life: 3000,
        });
      }
    };

    const handleError = (e) => {
      const errorDetails = e.xhr?.response || "No response details";
      const statusCode = e.xhr?.status || "Unknown status";

      toast.current.show({
        severity: "error",
        summary: "Upload Failed",
        detail: `Error uploading to ${id}: ${statusCode} - ${errorDetails}`,
        life: 5000,
      });
    };

    return (
      <div className="my-4 p-field">
        <label htmlFor={id}>{label}</label>
        <FileUpload
          id={id}
          mode="basic"
          name="img"
          url={`${apiUrl}media/api/upload`}
          accept="image/*, video/*"
          maxFileSize={35000000}
          onUpload={handleUpload}
          onError={handleError}
          chooseLabel="Drop here"
          auto
        />
        <MediaGallery
          images={images}
          onRemove={(e, file) => {
            onRemoveHandler(e, file);
          }}
        />
      </div>
    );
  };
  // Submit button component
  const SubmitButton = () => (
    <div className="flex items-center justify-center">
      <Button
        label={"Submit"}
        type="submit"
        icon={"pi pi-check"}
        className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
        loading={updateCustomerLoading || updateOperationLoading}
      />
    </div>
  );

  // Form content with common fields for both views
  const FormContent = () => (
    <>
      <div className="flex flex-col my-4">
        <h3 className="text-lg font-bold">Assigned To</h3>
        <span>{operationData?.technician?.full_name}</span>
      </div>

      {!isUnWrapping && <SubmitButton />}

      {!isUnWrapping && operationData?.order && (
        <FormField
          name="modeOfPayment"
          label="Mode of Payment"
          control={control}
          errors={errors}
        >
          {(field, fieldState) => (
            <Dropdown
              id={field.name}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              options={modeOfPaymentOptions}
              optionLabel="name"
              className={`${classNames({
                "p-invalid": fieldState.error,
              })} w-full rounded-xl`}
            />
          )}
        </FormField>
      )}

      {isUnWrapping && operationData?.order?.payment_status && (
        <FormField
          name="payment_status"
          label="Payment Status"
          control={control}
          errors={errors}
          disabled={true}
        >
          {(field, fieldState, disabled) => (
            <InputText
              id={field.name}
              value={field.value || operationData?.order?.payment_status}
              className={`${classNames({
                "p-invalid": fieldState.error,
              })} w-full rounded-xl bg-gray-100`}
              disabled={disabled}
              readOnly={disabled}
            />
          )}
        </FormField>
      )}

      {isUnWrapping && (
        <FormField
          name="orderUnpaidAmount"
          label="Order (Unpaid Amount)"
          control={control}
          errors={errors}
          disabled={true}
        >
          {(field, fieldState, disabled) => (
            <InputNumber
              id={field.name}
              value={field.value}
              className={`${classNames({
                "p-invalid": fieldState.error,
              })} w-full rounded-xl`}
              inputClassName="rounded-xl"
              disabled={disabled}
            />
          )}
        </FormField>
      )}

      {isUnWrapping && (
        <FileUploadSection
          id="treesMap"
          label="Trees Map"
          onUploadHandler={onUploadTreesMap}
          images={treesMapImages}
          onRemoveHandler={onRemoveTreesMapImages}
        />
      )}

      {isUnWrapping && <SubmitButton />}

      {isUnWrapping && operationData?.order && (
        <FormField
          name="modeOfPayment"
          label="Mode of Payment"
          control={control}
          errors={errors}
        >
          {(field, fieldState) => (
            <Dropdown
              id={field.name}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              options={modeOfPaymentOptions}
              optionLabel="name"
              className={`${classNames({
                "p-invalid": fieldState.error,
              })} w-full rounded-xl`}
            />
          )}
        </FormField>
      )}

      <FormField name="status" label="Status" control={control} errors={errors}>
        {(field, fieldState) => (
          <Dropdown
            id={field.name}
            value={field.value}
            onChange={(e) => field.onChange(e.value)}
            options={statusOPtions}
            optionLabel="name"
            className={`${classNames({
              "p-invalid": fieldState.error,
            })} w-full rounded-xl`}
          />
        )}
      </FormField>

      <FormField name="date" label="Date" control={control} errors={errors}>
        {(field, fieldState) => (
          <Calendar
            id={field.name}
            value={field.value}
            onChange={(e) => field.onChange(e.value)}
            dateFormat="mm/dd/yy"
            className={`${classNames({
              "p-invalid": fieldState.error,
            })} w-full rounded-xl`}
            inputClassName="rounded-xl"
          />
        )}
      </FormField>

      {isUnWrapping && (
        <FormField
          name="road_sequence"
          label="Sequence"
          control={control}
          errors={errors}
        >
          {(field, fieldState) => (
            <InputNumber
              id={field.name}
              value={field.value}
              className={`${classNames({
                "p-invalid": fieldState.error,
              })} w-full rounded-xl`}
              onValueChange={(e) => field.onChange(e.value)}
              inputClassName="rounded-xl"
              min={0}
              useGrouping={false}
            />
          )}
        </FormField>
      )}

      <FormField
        name="total_number_of_trees"
        label="Total Numbers Of Trees"
        control={control}
        errors={errors}
        disabled={operationData?.order?.payment_status === "Paid in Full"}
      >
        {(field, fieldState, disabled) => (
          <InputNumber
            id={field.name}
            value={field.value}
            className={`${classNames({
              "p-invalid": fieldState.error,
            })} w-full rounded-xl`}
            onValueChange={(e) => field.onChange(e.value)}
            inputClassName="rounded-xl"
            min={0}
            disabled={disabled}
          />
        )}
      </FormField>

      <div className="my-4">
        <Controller
          name="want_treat_response"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} font-semibold text-xl`}
              >
                Is the customer interested in receiving treatment?
              </label>
              <div className="flex flex-wrap gap-4 my-4">
                <div className="flex">
                  <RadioButton
                    inputId="Yes"
                    name="category"
                    value={"true"}
                    checked={field.value === "true"}
                    onChange={(e) => field.onChange(e.target.value)}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} mr-1`}
                    inputRef={field.ref}
                  />
                  <label htmlFor="Yes" className="ml-1 mr-3">
                    Yes
                  </label>
                </div>
                <div className="flex">
                  <RadioButton
                    inputId="No"
                    name="category"
                    value={"false"}
                    onChange={(e) => field.onChange(e.target.value)}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} mr-1`}
                    checked={field.value === "false"}
                  />
                  <label htmlFor="No" className="ml-1 mr-3">
                    No
                  </label>
                </div>
              </div>
              {getFormErrorMessage(field.name, errors)}
            </div>
          )}
        />
      </div>

      <FormField
        name="orderUnpaidAmount"
        label="Order (Unpaid Amount)"
        control={control}
        errors={errors}
        disabled={true}
      >
        {(field, fieldState, disabled) => (
          <InputNumber
            id={field.name}
            value={field.value}
            className={`${classNames({
              "p-invalid": fieldState.error,
            })} w-full rounded-xl`}
            inputClassName="rounded-xl"
            disabled={disabled}
          />
        )}
      </FormField>

      {!isUnWrapping && (
        <FormField
          name="road_sequence"
          label="Sequence"
          control={control}
          errors={errors}
        >
          {(field, fieldState) => (
            <InputNumber
              id={field.name}
              value={field.value}
              className={`${classNames({
                "p-invalid": fieldState.error,
              })} w-full rounded-xl`}
              onValueChange={(e) => field.onChange(e.value)}
              inputClassName="rounded-xl"
              min={0}
              useGrouping={false}
            />
          )}
        </FormField>
      )}

      <FormField
        name="howPowered"
        label="How Powered"
        control={control}
        errors={errors}
      >
        {(field, fieldState) => (
          <InputTextarea
            id={field.name}
            {...field}
            rows={4}
            cols={30}
            className={`${classNames({
              "p-invalid": fieldState.error,
            })} w-full rounded-xl`}
            autoResize
          />
        )}
      </FormField>

      <FormField
        name="tallestFrame"
        label="Tallest Frame"
        control={control}
        errors={errors}
      >
        {(field, fieldState) => (
          <InputText
            id={field.name}
            value={field.value}
            className={`${classNames({
              "p-invalid": fieldState.error,
            })} w-full rounded-xl`}
            onChange={(e) => field.onChange(e.target.value)}
          />
        )}
      </FormField>

      <FormField
        name="brokenFrames"
        label="Broken Frames"
        control={control}
        errors={errors}
      >
        {(field, fieldState) => (
          <InputText
            id={field.name}
            value={field.value}
            className={`${classNames({
              "p-invalid": fieldState.error,
            })} w-full rounded-xl`}
            onChange={(e) => field.onChange(e.target.value)}
          />
        )}
      </FormField>

      {/* Render unwrapping-specific fields if applicable */}
      {isUnWrapping && (
        <>
          <FormField
            name="extensions_needed"
            label="Extensions Needed"
            control={control}
            errors={errors}
          >
            {(field, fieldState) => (
              <InputNumber
                id={field.name}
                value={field.value}
                onValueChange={(e) => {
                  const value =
                    e.value === null || e.value === "" ? 1 : e.value;
                  field.onChange(Math.max(1, Math.min(10, value)));
                }}
                min={1}
                max={10}
                showButtons
                buttonLayout="horizontal"
                decrementButtonClassName="p-button-success border-[#FFEDED] text-white bg-[#3a5d4c]"
                incrementButtonClassName="p-button-success border-[#FFEDED] text-white bg-[#3a5d4c]"
                className={`${classNames({
                  "p-invalid": fieldState.error,
                })} w-full`}
                style={{ height: "45px" }}
              />
            )}
          </FormField>

          <FormField
            name="pole_replacement_needed"
            label="Pole Replacement Needed"
            control={control}
            errors={errors}
          >
            {(field, fieldState) => (
              <InputNumber
                id={field.name}
                value={field.value}
                onValueChange={(e) => {
                  const value =
                    e.value === null || e.value === "" ? 1 : e.value;
                  field.onChange(Math.max(1, Math.min(10, value)));
                }}
                min={1}
                max={10}
                showButtons
                buttonLayout="horizontal"
                decrementButtonClassName="p-button-success border-[#FFEDED] text-white bg-[#3a5d4c]"
                incrementButtonClassName="p-button-success border-[#FFEDED] text-white bg-[#3a5d4c]"
                className={`${classNames({
                  "p-invalid": fieldState.error,
                })} w-full`}
                style={{ height: "45px" }}
              />
            )}
          </FormField>

          <FormField
            name="replacement_cover_needed"
            label="Replacement Cover Needed"
            control={control}
            errors={errors}
          >
            {(field, fieldState) => (
              <InputNumber
                id={field.name}
                value={field.value}
                onValueChange={(e) => {
                  const value =
                    e.value === null || e.value === "" ? 1 : e.value;
                  field.onChange(Math.max(1, Math.min(10, value)));
                }}
                min={1}
                max={10}
                showButtons
                buttonLayout="horizontal"
                decrementButtonClassName="p-button-success border-[#FFEDED] text-white bg-[#3a5d4c]"
                incrementButtonClassName="p-button-success border-[#FFEDED] text-white bg-[#3a5d4c]"
                className={`${classNames({
                  "p-invalid": fieldState.error,
                })} w-full`}
                style={{ height: "45px" }}
              />
            )}
          </FormField>

          <FormField
            name="kitLocationText"
            label="Kit Location - Text"
            control={control}
            errors={errors}
          >
            {(field, fieldState) => (
              <InputText
                id={field.name}
                value={field.value}
                className={`${classNames({
                  "p-invalid": fieldState.error,
                })} w-full rounded-xl`}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          </FormField>

          <FormField
            name="poleLocationText"
            label="Pole Location - Text"
            control={control}
            errors={errors}
          >
            {(field, fieldState) => (
              <InputText
                id={field.name}
                value={field.value}
                className={`${classNames({
                  "p-invalid": fieldState.error,
                })} w-full rounded-xl`}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          </FormField>

          <FormField
            name="water_location"
            label="Water Location"
            control={control}
            errors={errors}
          >
            {(field, fieldState) => (
              <InputText
                id={field.name}
                {...field}
                className={`${classNames({
                  "p-invalid": fieldState.error,
                })} w-full rounded-xl`}
              />
            )}
          </FormField>
        </>
      )}

      <ProductsOrderedSection />

      {isUnWrapping && (
        <FormField
          name="orderUnpaidAmount"
          label="Order (Unpaid Amount)"
          control={control}
          errors={errors}
          disabled={true}
        >
          {(field, fieldState, disabled) => (
            <InputNumber
              id={field.name}
              value={field.value}
              className={`${classNames({
                "p-invalid": fieldState.error,
              })} w-full rounded-xl`}
              inputClassName="rounded-xl"
              disabled={disabled}
            />
          )}
        </FormField>
      )}

      {isUnWrapping && operationData?.order && (
        <FormField
          name="modeOfPayment"
          label="Mode of Payment"
          control={control}
          errors={errors}
        >
          {(field, fieldState) => (
            <Dropdown
              id={field.name}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              options={modeOfPaymentOptions}
              optionLabel="name"
              className={`${classNames({
                "p-invalid": fieldState.error,
              })} w-full rounded-xl`}
            />
          )}
        </FormField>
      )}

      <FormField
        name="comments"
        label="Notes/Comments"
        control={control}
        errors={errors}
      >
        {(field, fieldState) => (
          <InputTextarea
            id={field.name}
            {...field}
            rows={4}
            cols={30}
            className={`${classNames({
              "p-invalid": fieldState.error,
            })} w-full rounded-xl`}
            autoResize
          />
        )}
      </FormField>

      <FormField
        name="commentsTech"
        label="Internal Notes (From Technician)"
        control={control}
        errors={errors}
      >
        {(field, fieldState) => (
          <InputTextarea
            id={field.name}
            {...field}
            rows={4}
            cols={30}
            className={`${classNames({
              "p-invalid": fieldState.error,
            })} w-full rounded-xl`}
            autoResize
          />
        )}
      </FormField>

      <FormField
        name="to_do_notes"
        label="General Customer Notes"
        control={control}
        errors={errors}
      >
        {(field, fieldState) => (
          <InputTextarea
            id={field.name}
            {...field}
            rows={4}
            cols={30}
            className={`${classNames({
              "p-invalid": fieldState.error,
            })} w-full rounded-xl`}
            autoResize
          />
        )}
      </FormField>

      {operationData?.order && (
        <FormField
          name="clientBillingNotes"
          label="Client Billing Notes"
          control={control}
          errors={errors}
        >
          {(field, fieldState) => (
            <InputTextarea
              id={field.name}
              {...field}
              rows={4}
              cols={30}
              className={`${classNames({
                "p-invalid": fieldState.error,
              })} w-full rounded-xl`}
              autoResize
            />
          )}
        </FormField>
      )}

      <TreeConditionsSection />

      {!isUnWrapping && (
        <>
          <FileUploadSection
            id="kitLocation"
            label="Kit Location"
            onUploadHandler={onUpload}
            images={kitLocationImages}
            onRemoveHandler={onRemoveImage}
          />
          <FormField
            name="kitLocationText"
            label="Kit Location - Text"
            control={control}
            errors={errors}
          >
            {(field, fieldState) => (
              <InputText
                id={field.name}
                value={field.value}
                className={`${classNames({
                  "p-invalid": fieldState.error,
                })} w-full rounded-xl`}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          </FormField>
        </>
      )}

      {!isUnWrapping && (
        <>
          <FileUploadSection
            id="poleLocation"
            label="Pole Location"
            onUploadHandler={onUploadPoleLocation}
            images={poleLocationImages}
            onRemoveHandler={onRemovePoleLocationImages}
          />
          <FormField
            name="poleLocationText"
            label="Pole Location - Text"
            control={control}
            errors={errors}
          >
            {(field, fieldState) => (
              <InputText
                id={field.name}
                value={field.value}
                className={`${classNames({
                  "p-invalid": fieldState.error,
                })} w-full rounded-xl`}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          </FormField>
        </>
      )}

      {!isUnWrapping && (
        <FileUploadSection
          id="treesMap"
          label="Trees Map"
          onUploadHandler={onUploadTreesMap}
          images={treesMapImages}
          onRemoveHandler={onRemoveTreesMapImages}
        />
      )}

      <SubmitButton />
    </>
  );

  return (
    <>
      <ConfirmDialog />
      {treeId && (
        <ShowTreeDetails
          setShowTreeDetails={setShowTreeDetails}
          showTreeDetails={showTreeDetails}
          treeId={treeId}
        />
      )}
      {treeId && (
        <EditTree
          treeId={treeId}
          showTreeEdit={showTreeEdit}
          setShowTreeEdit={setShowTreeEdit}
          toast={toast}
          refetch={refetch}
        />
      )}
      {operationId && (
        <AddTree
          showAddTree={showAddTree}
          setShowAddTree={setShowAddTree}
          toast={toast}
          customerId={customerId}
          operationId={operationId}
          refetch={refetch}
        />
      )}
      {operationData?.customer?.id && (
        <AddOrderLineOperation
          refetch={refetch}
          setShowOrderDialog={setShowOrderLine}
          showOrderDialog={showOrderLine}
          showOrderCustomerId={operationData?.customer?.id}
          toast={toast}
          orderID={operationData?.order?.id}
        />
      )}
      <VideoPlayerDialog
        file={file}
        videoVisible={videoVisible}
        setVideoVisible={setVideoVisible}
      />
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContent />
        </form>
      )}
    </>
  );
};

export default EditNoteDialog;
