import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../axios/axios";

export const assignTechnician = createAsyncThunk(
  "operation/assignTechnician",
  async ({ operationId, technicianId }, { rejectWithValue, getState }) => {
    const token = getState().auth.token
      ? getState().auth.token
      : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status } = await instance.put(
        `treatment-ops/${operationId}/tech/${technicianId}`,
        {},
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAddNoteOperation = createAsyncThunk(
  "addNoteOperation/update",
  async (
    {
      id,
      date_of_tech_visit,
      notes_comments,
      internal_notes,
      number_of_old_trees_to_wrap,
      extension_type,
      extension_note,
      number_of_extension_pole,
      number_of_extra_lights,
      status,
      client_billing_notes,
      mode_of_payment,
      travel_surcharge,
      total_number_of_trees,
      customer_input_notes,
      road_sequence,
      want_treat_response,
      // Unwrapping fields
      extensions_needed,
      pole_replacement_needed,
      replacement_cover_needed,
      water_location,
    },
    { rejectWithValue, getState }
  ) => {
    const token = getState().auth.token
      ? getState().auth.token
      : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const payload = {
        total_number_of_trees,
        date_of_tech_visit,
        notes_comments,
        internal_notes,
        number_of_old_trees_to_wrap,
        extension_type,
        extension_note,
        number_of_extension_pole,
        number_of_extra_lights,
        status,
        client_billing_notes,
        mode_of_payment,
        travel_surcharge,
        customer_input_notes,
        road_sequence,
        want_treat_response,
      };

      if (extensions_needed !== undefined) {
        payload.extensions_needed = extensions_needed;
      }

      if (pole_replacement_needed !== undefined) {
        payload.pole_replacement_needed = pole_replacement_needed;
      }

      if (replacement_cover_needed !== undefined) {
        payload.replacement_cover_needed = replacement_cover_needed;
      }

      if (water_location !== undefined) {
        payload.water_location = water_location;
      }

      const { data, status: reqStatus } = await instance.patch(
        `treatment-ops/${id}`,
        payload,
        config
      );
      return { data, reqStatus };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateAddNoteCustomer = createAsyncThunk(
  "addNoteOperation/updateCustomer",
  async (
    {
      id,
      kit_location,
      pole_location,
      kit_location_imgs,
      pole_location_imgs,
      trees_map,
      tallest_frame,
      how_powered,
      broken_frames,
      to_do_notes,
    },
    { rejectWithValue, getState }
  ) => {
    const token = getState().auth.token
      ? getState().auth.token
      : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status } = await instance.patch(
        `customers/${id}`,
        {
          kit_location,
          pole_location,
          kit_location_imgs: kit_location_imgs || [],
          pole_location_imgs: pole_location_imgs || [],
          trees_map: trees_map || [],
          tallest_frame,
          how_powered,
          broken_frames,
          to_do_notes,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAddNoteOrder = createAsyncThunk(
  "addNoteOperation/updateOrder",
  async (
    { id, mode_of_payment, client_billing_notes },
    { rejectWithValue, getState }
  ) => {
    const token = getState().auth.token
      ? getState().auth.token
      : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status } = await instance.patch(
        `orders/${id}`,
        {
          client_billing_notes,
          mode_of_payment,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteOperation = createAsyncThunk(
  "operation/delete",
  async ({ id }, { rejectWithValue, getState }) => {
    const token = getState().auth.token
      ? getState().auth.token
      : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status } = await instance.delete(
        `treatment-ops/${id}`,
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
