import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { TabView, TabPanel } from "primereact/tabview";

import AddTask from "../tasks/AddTask";
import CustomerEdit from "./CustomerEdit";
import SendEmailSms from "./SendEmailSms";
import AddOrder from "../Orders/AddOrder";
import CommunicationDialog from "./CommunicationDialog";
import {
  useGetAllCustomerQuery,
  useGetAllCustomerByLastCommunicationQuery,
} from "../../services/customer/customerServices";
import { deleteCustomer } from "../../features/customer/customerActions";
import CustomerDetailsDialog from "./CustomerDetailsDialog";
// import { fetchCoordinatesFromGeoCode } from "../../services/map/addressToCoordinates";

const CustomerTableContent = () => {
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [deletedCustomerId, setDeletedCustomerId] = useState(null);
  const [deletedCustomerVisible, setDeletedCustomerVisible] = useState(false);
  const [editCustomerVisible, setEditCustomerVisible] = useState(false);
  const [editCustomerId, setEditCustomerId] = useState(null);
  const [showOrderCustomerVisible, setShowOrderCustomerVisible] =
    useState(false);
  const [addTaskVisible, setAddTaskVisible] = useState(false);
  const [showOrderCustomerId, setShowOrderCustomerId] = useState(null);
  const [showTaskCustomerId, setShowTaskCustomerId] = useState(null);
  const [ShowCommunicateCustomerId, setShowCommunicateCustomerId] =
    useState(null);
  const [ShowCommunicateCustomerVisible, setShowCommunicateCustomerVisible] =
    useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [CustomerName, setCustomerName] = useState(null);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const toast = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: customersData,
    error,
    isLoading,
    refetch,
  } = useGetAllCustomerQuery("customerDetails", {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: lastCommunicationData,
    error: lastCommunicationError,
    isLoading: isLastCommunicationLoading,
  } = useGetAllCustomerByLastCommunicationQuery("customerDetails", {
    refetchOnMountOrArgChange: true,
  });

  const onShowCommunication = (id) => {
    setVisibleDialog(true);
    setCustomerId(id);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
  };

  const nameBodyTemplate = (rowData) => {
    return <div className="py-4 ">{rowData.full_name}</div>;
  };

  const emailBodyTemplate = (rowData) => {
    return <div className="p-4 ">{rowData.email}</div>;
  };

  const phoneBodyTemplate = (rowData) => {
    return <div className="py-4 ">{rowData.phone}</div>;
  };

  const addressBodyTemplate = (rowData) => {
    return <div className="py-4 ">{rowData.full_address}</div>;
  };

  // const formSentBodyTemplate = (rowData) => {
  //   return (
  //     <div className={` py-4 `}>
  //       <i
  //         className={`${classNames("pi", {
  //           "true-icon pi-check-circle text-blue-600": rowData.is_send_form,
  //           "false-icon pi-times-circle text-red-500": !rowData.is_send_form,
  //         })}`}
  //       ></i>
  //     </div>
  //   );
  // };

  const contactAgainBodyTemplate = (rowData) => {
    return (
      <div className={` py-4 `}>
        <i
          className={`${classNames("pi", {
            "true-icon pi-check-circle text-blue-600":
              !rowData.do_not_contact_again,
            "false-icon pi-times-circle text-red-500":
              rowData.do_not_contact_again,
          })}`}
        ></i>
      </div>
    );
  };

  const haveWindmillBodyTemplate = (rowData) => {
    return (
      <div className={` py-4 `}>
        <i
          className={`${classNames("pi", {
            "true-icon pi-check-circle text-blue-600": rowData.ordered_windmill,
            "false-icon pi-times-circle text-red-500":
              !rowData.ordered_windmill,
          })}`}
        ></i>
      </div>
    );
  };

  const prevTreatmentBodyTemplate = (rowData) => {
    return (
      <div className={` py-4 `}>
        <i
          className={`${classNames("pi", {
            "true-icon pi-check-circle text-blue-600": rowData.prev_treatements,
            "false-icon pi-times-circle text-red-500":
              !rowData.prev_treatements,
          })}`}
        ></i>
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex items-center justify-between bg-none">
        <div className="flex">
          <span className="mr-2 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm rounded-xl"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
        <div className="flex">
          <div className="flex items-center gap-2">
            <Button
              onClick={() => navigate("/customerCommu")}
              icon=""
              label="Communicate to customers"
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              size="small"
              //onClick={}
            />
            <Button
              icon="pi pi-plus"
              label="Add Customer"
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              size="small"
              onClick={() => navigate("/addcustomer")}
            />
          </div>
        </div>
      </div>
    );
  };

  const onDelete = (id) => {
    setDeletedCustomerId(id);
    setDeletedCustomerVisible(true);
  };

  const onEdit = (id) => {
    setEditCustomerId(id);
    setEditCustomerVisible(true);
  };

  const onShowAddOrder = (id) => {
    setShowOrderCustomerId(id);
    setShowOrderCustomerVisible(true);
  };

  const onShowDetails = (id, name) => {
    setCustomerId(id);
    setDetailsVisible(true);
  };

  const onShowAddTask = (id, full_name) => {
    setShowTaskCustomerId(id);
    setCustomerName(full_name);
    setAddTaskVisible(true);
  };

  const onCommunicate = (id) => {
    setShowCommunicateCustomerId(id);
    setShowCommunicateCustomerVisible(true);
  };

  const onDeleteHandler = () => {
    dispatch(deleteCustomer({ id: deletedCustomerId })).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        setDeletedCustomerVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setDeletedCustomerVisible(false)}
        className="p-button-text"
        text
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={() => onDeleteHandler()}
        text
      />
    </div>
  );

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p>{error?.error}</p>
      </div>
    );
  }

  return (
    <div>
      <Toast ref={toast} />
      {customerId && (
        <CommunicationDialog
          visible={visibleDialog}
          setVisible={setVisibleDialog}
          customerId={customerId}
        />
      )}
      {ShowCommunicateCustomerId && (
        <SendEmailSms
          visible={ShowCommunicateCustomerVisible}
          setVisible={setShowCommunicateCustomerVisible}
          refetch={refetch}
          toast={toast}
          selectedIds={[ShowCommunicateCustomerId]}
        />
      )}
      {showOrderCustomerId && (
        <AddOrder
          showOrderCustomerVisible={showOrderCustomerVisible}
          setShowOrderCustomerVisible={setShowOrderCustomerVisible}
          showOrderCustomerId={showOrderCustomerId}
          toast={toast}
        />
      )}
      {showTaskCustomerId && (
        <AddTask
          addProductVisible={addTaskVisible}
          setAddProductVisible={setAddTaskVisible}
          CustomerId={showTaskCustomerId}
          CustomerName={CustomerName}
          toast={toast}
          refetch={refetch}
        />
      )}
      {editCustomerId && (
        <CustomerEdit
          editCustomerId={editCustomerId}
          setEditCustomerVisible={setEditCustomerVisible}
          editCustomerVisible={editCustomerVisible}
          refetch={refetch}
          toast={toast}
        />
      )}
      {customerId && (
        <CustomerDetailsDialog
          refetch={refetch}
          toast={toast}
          customerId={customerId}
          setDetailsVisible={setDetailsVisible}
          detailsVisible={detailsVisible}
          setCustomerId={setCustomerId}
        />
      )}
      <Dialog
        header="Delete"
        visible={deletedCustomerVisible}
        style={{ width: "50vw" }}
        onHide={() => setDeletedCustomerVisible(false)}
        footer={footerContent}
      >
        <p className="m-0 text-center">
          Are you sure to delete this treatment?
        </p>
      </Dialog>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Last Modification">
          <DataTable
            value={customersData?.data}
            dataKey="id"
            scrollable
            scrollHeight="68vh"
            loading={isLoading}
            header={header}
            globalFilter={globalFilterValue}
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onRowClick={(e) => {
              onShowDetails(e.data?.id, e.data?.full_name);
            }}
            rowClassName="cursor-pointer"
          >
            <Column
              style={{ minWidth: "200px" }}
              field="full_name"
              header="Full Name"
              body={nameBodyTemplate}
            />
            <Column
              style={{ minWidth: "200px" }}
              field="email"
              header="E-mail"
              body={emailBodyTemplate}
            />
            <Column
              style={{ minWidth: "200px" }}
              field="phone"
              header="Phone"
              body={phoneBodyTemplate}
            />
            <Column
              style={{ minWidth: "200px" }}
              field="full_address"
              header="Address"
              body={addressBodyTemplate}
            />
          </DataTable>
        </TabPanel>

        <TabPanel header="Last Communication">
          <DataTable
            value={lastCommunicationData?.data}
            dataKey="id"
            scrollable
            scrollHeight="68vh"
            loading={isLastCommunicationLoading}
            header={header}
            globalFilter={globalFilterValue}
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onRowClick={(e) => {
              onShowDetails(e.data?.id, e.data?.full_name);
            }}
            rowClassName="cursor-pointer"
          >
            <Column
              style={{ minWidth: "200px" }}
              field="full_name"
              header="Full Name"
              body={nameBodyTemplate}
            />
            <Column
              style={{ minWidth: "200px" }}
              field="email"
              header="E-mail"
              body={emailBodyTemplate}
            />
            <Column
              style={{ minWidth: "200px" }}
              field="phone"
              header="Phone"
              body={phoneBodyTemplate}
            />
            <Column
              style={{ minWidth: "200px" }}
              field="full_address"
              header="Address"
              body={addressBodyTemplate}
            />
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default CustomerTableContent;
