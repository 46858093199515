import { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import VideoPlayerDialog from "./VideoPlayerDialog";
import TreeConditionsDialog from "./TreeConditionsDialog";

import styles from "./ClientTreatmentTable.module.css";

import { formatCurrency } from "../../utils/formatCurrency";
import { BigContainer, ImagesSection } from "./OperationDialog";
import AddOrderLineOperation from "../OrderLines/AddOrderLineOperation";

const OperationDialogDetails = ({
  operationData,
  isLoading,
  error,
  refetch,
}) => {
  const [videoVisible, setVideoVisible] = useState(false);
  const [visibleTree, setVisibleTree] = useState(false);
  const [orderLine, setOrderLine] = useState(null);
  const [tree, setTree] = useState(null);
  const [file, setFile] = useState("");

  const toast = useRef(null);

  const showTreeDialog = (tree) => {
    setVisibleTree(true);
    setTree(tree);
  };

  const calculateTotal = () => {
    return operationData?.order?.line_items?.reduce(
      (total, item) => total + item.total_price,
      0
    );
  };

  const isUnWrapping =
    operationData?.treatment?.title?.split(" - ")[0] === "Unwrapping";

  const Cash = () => (
    <>
      <BigContainer
        titleOne="Mode Of Payment"
        valueOne={operationData?.order?.mode_of_payment}
        titleTwo="Order Total"
        valueTwo={formatCurrency(operationData?.order?.order_unpaid_amount)}
      />
      <BigContainer
        titleOne="Discount"
        valueOne={formatCurrency(operationData?.order?.discount)}
        titleTwo="Client Billing Notes"
        valueTwo={operationData?.client_billing_notes}
      />
    </>
  );

  const Check = () => (
    <>
      <BigContainer
        titleOne="Mode Of Payment"
        valueOne={operationData?.order?.mode_of_payment}
        titleTwo="Order Total (Before Taxes/Fees)"
        valueTwo={formatCurrency(
          operationData?.order?.order_invoice?.invoice_total_before_taxes_fees
        )}
      />
      <BigContainer
        titleTwo="Client Billing Notes"
        valueTwo={formatCurrency(operationData?.client_billing_notesl)}
        titleOne="Order Total (After Taxes)"
        valueOne={formatCurrency(
          operationData?.order?.order_invoice?.invoice_total_after_taxes
        )}
      />
    </>
  );

  const CreditCard = () => (
    <>
      <BigContainer
        titleOne="Mode Of Payment"
        valueOne={operationData?.order?.mode_of_payment}
        titleTwo="Order Total (Before Taxes/Fees)"
        valueTwo={formatCurrency(
          operationData?.order?.order_invoice?.invoice_total_before_taxes_fees
        )}
      />
      <BigContainer
        titleOne="Order (Unpaid Amount After Taxes)"
        valueOne={operationData?.order?.order_unpaid_amount_after_taxes}
        titleTwo="Order Total"
        valueTwo={formatCurrency(
          operationData?.order?.order_invoice?.invoice_total
        )}
      />
      <BigContainer
        titleOne="Order Total (Before Taxes/Fees)"
        valueOne={formatCurrency(
          operationData?.order?.order_invoice?.invoice_total_before_taxes_fees
        )}
        titleTwo="Order Total (After Taxes)"
        valueTwo={formatCurrency(
          operationData?.order?.order_invoice?.invoice_total_after_taxes
        )}
      />
      <BigContainer
        titleTwo="Order Total"
        valueTwo={formatCurrency(
          operationData?.order?.order_invoice?.invoice_total
        )}
        titleOne="Order Total (After Processing Fees)"
        valueOne={formatCurrency(
          operationData?.order?.order_invoice
            ?.invoice_total_after_processing_fees
        )}
      />
      <BigContainer
        titleOne="Discount"
        valueOne={formatCurrency(operationData?.order?.discount)}
        titleTwo="Client Billing Notes"
        valueTwo={operationData?.client_billing_notes}
      />
    </>
  );

  const CashDepositCreditCardBalance = () => (
    <>
      <BigContainer
        titleOne="Mode Of Payment"
        valueOne={operationData?.order?.mode_of_payment}
        titleTwo="Deposit"
        valueTwo={formatCurrency(operationData?.order?.deposit)}
      />
      <BigContainer
        titleTwo="Order (Unpaid Amount)"
        valueTwo={formatCurrency(operationData?.order?.order_unpaid_amount)}
        titleOne="Order (Unpaid Amount After Taxes)"
        valueOne={formatCurrency(
          operationData?.order?.order_unpaid_amount_after_taxes
        )}
      />
      <BigContainer
        titleOne="Order (Unpaid Amount After Processing)"
        valueOne={formatCurrency(
          operationData?.order?.order_unpaid_amount_after_processing
        )}
        titleTwo="Remaining (After Deposit)"
        valueTwo={formatCurrency(operationData?.order?.remaining_after_deposit)}
      />
      <BigContainer
        titleTwo="Deposit (After Taxes)"
        valueTwo={formatCurrency(
          operationData?.order?.remaining_after_deposit_after_taxes
        )}
        titleOne="Remaining (After Deposit After Fees)"
        valueOne={formatCurrency(
          operationData?.order?.remaining_after_deposit_after_fees
        )}
      />
      <BigContainer
        titleOne="Discount"
        valueOne={formatCurrency(operationData?.order?.discount)}
        titleTwo="Client Billing Notes"
        valueTwo={operationData?.client_billing_notes}
      />
    </>
  );

  const CreditCardDepositCashBalance = () => (
    <>
      <BigContainer
        titleOne="Mode Of Payment"
        valueOne={operationData?.order?.mode_of_payment}
        titleTwo="Deposit"
        valueTwo={formatCurrency(operationData?.order?.deposit)}
      />
      <BigContainer
        titleTwo="Deposit (After Taxes)"
        valueTwo={formatCurrency(operationData?.order?.deposit_after_taxes)}
        titleOne="Deposit (After Processing Fees)"
        valueOne={formatCurrency(
          operationData?.order?.order_invoice?.deposit_after_processing_fees
        )}
      />
      <BigContainer
        titleTwo="Order (Unpaid Amount)"
        valueTwo={formatCurrency(operationData?.order?.order_unpaid_amount)}
        titleOne="Order (Unpaid Amount After Taxes)"
        valueOne={formatCurrency(
          operationData?.order?.order_invoice?.order_unpaid_amount_after_taxes
        )}
      />
      <BigContainer
        titleOne="Order (Unpaid Amount After Processing)"
        valueOne={formatCurrency(
          operationData?.order?.order_unpaid_amount_after_processing
        )}
        titleTwo="Remaining (After Deposit)"
        valueTwo={formatCurrency(operationData?.order?.remaining_after_deposit)}
      />
      <BigContainer
        titleOne="Discount"
        valueOne={formatCurrency(operationData?.order?.discount)}
        titleTwo="Client Billing Notes"
        valueTwo={operationData?.client_billing_notes}
      />
    </>
  );

  const onOrderComponentShow = (type) => {
    switch (type) {
      case "Cash":
        return <Cash />;
      case "Credit Card":
        return <CreditCard />;
      case "Cash Deposit / Credit Card Balance":
        return <CashDepositCreditCardBalance />;
      case "Credit Card Deposit / Cash Balance":
        return <CreditCardDepositCashBalance />;
      case "Check":
        return <Check />;
      default:
        return null;
    }
  };

  const onOrderLine = () => {
    setOrderLine(true);
  };

  const renderDefaultView = () => (
    <div className="flex flex-col gap-4">
      <ImagesSection
        imgTitle="Trees Map"
        imgsArr={operationData?.customer?.trees_map || []}
        setFile={setFile}
        setVideoVisible={setVideoVisible}
      />
      <BigContainer
        titleOne="Broken Frames"
        valueOne={operationData?.customer?.broken_frames}
        titleTwo="Tallest Frame"
        valueTwo={operationData?.customer?.tallest_frame}
      />
      <BigContainer
        titleOne="Date"
        valueOne={operationData?.date_of_tech_visit}
        titleTwo="Status"
        valueTwo={operationData?.status}
      />
      <BigContainer
        titleOne="Assigned To"
        valueOne={operationData?.technician?.full_name}
        titleTwo="Service"
        valueTwo={operationData?.treatment?.title}
      />
      <BigContainer
        titleOne="Address"
        valueOne={operationData?.customer?.full_address}
        titleTwo="How Powered"
        valueTwo={operationData?.customer?.how_powered}
      />
      <BigContainer
        titleOne="Sequence"
        valueOne={operationData?.road_sequence}
        titleTwo="Want Treatment"
        valueTwo={
          operationData?.want_treat_response === "true"
            ? "Yes"
            : operationData?.want_treat_response === "false"
            ? "No"
            : operationData?.want_treat_response
        }
      />
      <div>
        <h3 className={styles.detailsTitle}>Products Ordered</h3>
        <div className="flex flex-col gap-2">
          {operationData?.order?.line_items?.map((item) => (
            <p className={`!w-[96%] ${styles.detailsContent}`} key={item?.id}>
              {item?.line_item_quantity} X {item?.item_type} -{" "}
              {item?.item_type === "service"
                ? formatCurrency(item?.unit_price)
                : formatCurrency(item?.unit_price_override)}{" "}
            </p>
          ))}
        </div>
        <div>
          <Button
            label="Add OrderLine"
            onClick={onOrderLine}
            className="w-[96%] mt-4"
            disabled={operationData?.order?.payment_status === "Paid in Full"}
          />
        </div>
      </div>
      <div className={styles.detailsContainerDiv}>
        <h3 className={styles.detailsTitle}>Total</h3>
        <p className={styles.detailsContent}>
          {formatCurrency(calculateTotal())}
        </p>
      </div>
      {operationData?.order?.payment_status && (
        <div className={styles.detailsContainerDiv}>
          <h3 className={styles.detailsTitle}>Order Status</h3>
          <p className={styles.detailsContent}>
            {operationData?.order?.payment_status}
          </p>
        </div>
      )}
      {operationData?.order ? (
        onOrderComponentShow(operationData?.order?.mode_of_payment)
      ) : (
        <div className="flex items-center justify-center mx-auto rounded-md shadow-lg md:w-1/3 bg-slate-200">
          <p className="p-2 font-bold md:text-xl">No Order in this Operation</p>
        </div>
      )}
      <BigContainer
        titleOne="Internal Notes (From Technician)"
        valueOne={operationData?.internal_notes}
        titleTwo="Notes from Customer signUp Form"
        valueTwo={operationData?.customer_input_notes}
      />
      <BigContainer
        titleOne="Notes / Comments"
        valueOne={operationData?.notes_comments}
        titleTwo="General Customer Notes"
        valueTwo={operationData?.customer?.to_do_notes}
      />
      <div>
        <h3 className={styles.detailsTitle}>Kit Location - Text</h3>
        <p className={`!w-[96%] ${styles.detailsContent}`}>
          {operationData?.customer?.kit_location}
        </p>
      </div>
      <ImagesSection
        imgTitle="Kit Location - Images"
        imgsArr={operationData?.customer?.kit_location_imgs || []}
        setFile={setFile}
        setVideoVisible={setVideoVisible}
      />
      <div>
        <h3 className={styles.detailsTitle}>Pole Location - Text</h3>
        <p className={`!w-[96%] ${styles.detailsContent}`}>
          {operationData?.customer?.pole_location}
        </p>
      </div>
      <ImagesSection
        imgTitle="Pole Location - Images"
        imgsArr={operationData?.customer?.pole_location_imgs}
        setFile={setFile}
        setVideoVisible={setVideoVisible}
      />
      <div className="flex flex-col gap-4">
        <h3 className={styles.detailsTitle}>Tree Conditions</h3>
        <div className="flex flex-row gap-4">
          {operationData?.customer?.technician_reports?.map((tree) => (
            <Button key={`${tree.id}`} onClick={() => showTreeDialog(tree)}>
              {tree?.tree_number}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );

  const renderUnWrappingView = () => (
    <div className="flex flex-col gap-4">
      <ImagesSection
        imgTitle="Trees Map"
        imgsArr={operationData?.customer?.trees_map || []}
        setFile={setFile}
        setVideoVisible={setVideoVisible}
      />
      <BigContainer
        titleOne="Payment Status"
        valueOne={operationData?.order?.payment_status}
        titleTwo="Broken Frames"
        valueTwo={operationData?.customer?.broken_frames}
      />
      <BigContainer
        titleOne="Tallest Frame"
        valueOne={operationData?.customer?.tallest_frame}
        titleTwo="Extensions needed"
        valueTwo={operationData?.extensions_needed}
      />
      <BigContainer
        titleOne="Pole replacement needed"
        valueOne={operationData?.pole_replacement_needed}
        titleTwo="Replacement cover needed"
        valueTwo={operationData?.replacement_cover_needed}
      />
      <div>
        <h3 className={styles.detailsTitle}>Kit Location - Text</h3>
        <p className={`!w-[96%] ${styles.detailsContent}`}>
          {operationData?.customer?.kit_location}
        </p>
      </div>
      <div style={{ display: "none" }}>
        <ImagesSection
          imgTitle="Kit Location - Images"
          imgsArr={operationData?.customer?.kit_location_imgs || []}
          setFile={setFile}
          setVideoVisible={setVideoVisible}
        />
      </div>
      <div>
        <h3 className={styles.detailsTitle}>Pole Location - Text</h3>
        <p className={`!w-[96%] ${styles.detailsContent}`}>
          {operationData?.customer?.pole_location}
        </p>
      </div>
      <div style={{ display: "none" }}>
        <ImagesSection
          imgTitle="Pole Location - Images"
          imgsArr={operationData?.customer?.pole_location_imgs}
          setFile={setFile}
          setVideoVisible={setVideoVisible}
        />
      </div>
      <div style={{ display: "none" }}>
        <BigContainer
          titleOne="Date"
          valueOne={operationData?.date_of_tech_visit}
          titleTwo="Status"
          valueTwo={operationData?.status}
        />
      </div>

      <BigContainer
        titleOne="Water location"
        valueOne={operationData?.water_location}
        titleTwo="Status"
        valueTwo={operationData?.status}
      />
      <div style={{ display: "none" }}>
        <BigContainer
          titleOne="Assigned To"
          valueOne={operationData?.technician?.full_name}
          titleTwo="Service"
          valueTwo={operationData?.treatment?.title}
        />
      </div>
      <BigContainer
        titleOne="Service"
        valueOne={operationData?.treatment?.title}
        titleTwo="Address"
        valueTwo={operationData?.customer?.full_address}
      />
      <div style={{ display: "none" }}>
        <BigContainer
          titleOne="How Powered"
          valueOne={operationData?.customer?.how_powered}
        />
      </div>
      <div style={{ display: "none" }}>
        <BigContainer
          titleOne="Sequence"
          valueOne={operationData?.road_sequence}
        />
      </div>
      <div style={{ display: "none" }}>
        <BigContainer
          titleTwo="Want Treatment"
          valueTwo={
            operationData?.want_treat_response === "true"
              ? "Yes"
              : operationData?.want_treat_response === "false"
              ? "No"
              : operationData?.want_treat_response
          }
        />
      </div>
      <div style={{ display: "none" }}>
        <div>
          <h3 className={styles.detailsTitle}>Products Ordered</h3>
          <div className="flex flex-col gap-2">
            {operationData?.order?.line_items?.map((item) => (
              <p className={`!w-[96%] ${styles.detailsContent}`} key={item?.id}>
                {item?.line_item_quantity} X {item?.item_type} -{" "}
                {item?.item_type === "service"
                  ? formatCurrency(item?.unit_price)
                  : formatCurrency(item?.unit_price_override)}{" "}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <Button
          label="Add OrderLine"
          onClick={onOrderLine}
          className="w-[96%] mt-4"
          disabled={operationData?.order?.payment_status === "Paid in Full"}
        />
      </div>
      <div style={{ display: "none" }} className={styles.detailsContainerDiv}>
        <h3 className={styles.detailsTitle}>Total</h3>
        <p className={styles.detailsContent}>
          {formatCurrency(calculateTotal())}
        </p>
      </div>
      <div style={{ display: "none" }}>
        {operationData?.order?.payment_status && (
          <div className={styles.detailsContainerDiv}>
            <h3 className={styles.detailsTitle}>Payment Status</h3>
            <p className={styles.detailsContent}>
              {operationData?.order?.payment_status}
            </p>
          </div>
        )}
      </div>
      <div style={{ display: "none" }}>
        {operationData?.order ? (
          onOrderComponentShow(operationData?.order?.mode_of_payment)
        ) : (
          <div className="flex items-center justify-center mx-auto rounded-md shadow-lg md:w-1/3 bg-slate-200">
            <p className="p-2 font-bold md:text-xl">
              No Order in this Operation
            </p>
          </div>
        )}
      </div>
      <BigContainer
        titleOne="Internal Notes (From Technician)"
        valueOne={operationData?.internal_notes}
        titleTwo="Notes from Customer signUp Form"
        valueTwo={operationData?.customer_input_notes}
      />
      <BigContainer
        titleOne="Notes / Comments"
        valueOne={operationData?.notes_comments}
        titleTwo="General Customer Notes"
        valueTwo={operationData?.customer?.to_do_notes}
      />
      <div className="flex flex-col gap-4">
        <h3 className={styles.detailsTitle}>Tree Conditions</h3>
        <div className="flex flex-row gap-4">
          {operationData?.customer?.technician_reports?.map((tree) => (
            <Button key={`${tree.id}`} onClick={() => showTreeDialog(tree)}>
              {tree?.tree_number}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {operationData?.customer?.id && (
        <AddOrderLineOperation
          refetch={refetch}
          setShowOrderDialog={setOrderLine}
          showOrderDialog={orderLine}
          showOrderCustomerId={operationData?.customer?.id}
          toast={toast}
          orderID={operationData?.order?.id}
        />
      )}
      <Toast ref={toast} />
      <TreeConditionsDialog
        visible={visibleTree}
        setVisible={setVisibleTree}
        tree={tree}
      />
      <VideoPlayerDialog
        file={file}
        videoVisible={videoVisible}
        setVideoVisible={setVideoVisible}
      />
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <>{isUnWrapping ? renderUnWrappingView() : renderDefaultView()}</>
      )}
      {error && <p>{error?.data?.message}</p>}
    </>
  );
};

export default OperationDialogDetails;
